var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("運行履歴")]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "20px" } },
                [
                  _c("span", { class: _vm.$style.filterLabel }, [
                    _vm._v("閲覧対象:")
                  ]),
                  _c("a-radio-group", {
                    attrs: {
                      "button-style": "solid",
                      options: _vm.radioOptions
                    },
                    on: { change: _vm.changeTarget },
                    model: {
                      value: _vm.searchTarget,
                      callback: function($$v) {
                        _vm.searchTarget = $$v
                      },
                      expression: "searchTarget"
                    }
                  })
                ],
                1
              ),
              _c(
                "span",
                { staticClass: "mr-5", class: _vm.$style.filterHeader },
                [_vm._v("フィルター")]
              ),
              _vm.filterLoading === 3
                ? _c(
                    "a-form",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { layout: "inline" }
                    },
                    [
                      _vm.searchTarget !== 2 &&
                      _vm.myRole !== 3 &&
                      _vm.myCompanyPlan !== 4
                        ? _c(
                            "a-form-item",
                            { attrs: { label: "運転者" } },
                            [
                              _c("form-multiple-select", {
                                attrs: {
                                  placeholder: "運転者",
                                  candidates: _vm.users,
                                  styles: _vm.selectStyle,
                                  disabled: _vm.selectUserDisabled,
                                  defaultValue: _vm.selectUserDefaultValue
                                },
                                on: {
                                  changeSelectedItems: _vm.changeSelectedUsers
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.searchTarget !== 1
                        ? _c(
                            "a-form-item",
                            { attrs: { label: "支店名" } },
                            [
                              _c("form-multiple-select", {
                                attrs: {
                                  placeholder: "支店名",
                                  candidates: _vm.branches,
                                  styles: _vm.selectStyle,
                                  disabled: _vm.selectBranchDisabled,
                                  defaultValue: _vm.selectBranchDefaultValue
                                },
                                on: {
                                  changeSelectedItems:
                                    _vm.changeSelectedBranches
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.searchTarget !== 1
                        ? _c(
                            "a-form-item",
                            { attrs: { label: "車両" } },
                            [
                              _c("form-multiple-select", {
                                attrs: {
                                  placeholder: "車両",
                                  candidates: _vm.vehicles,
                                  styles: _vm.selectStyle,
                                  disabled: _vm.selectVehicleDisabled,
                                  defaultValue: _vm.selectVehicleDefaultValue
                                },
                                on: {
                                  changeSelectedItems:
                                    _vm.changeSelectedVehicles
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-config-provider",
                        { attrs: { locale: _vm.locale } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "対象期間" } },
                            [
                              _c("a-date-picker", {
                                staticStyle: { width: "130px" },
                                attrs: {
                                  locale: _vm.locale,
                                  defaultValue: _vm.selectDateStartDefaultValue,
                                  placeholder: "開始日"
                                },
                                on: { change: _vm.onChangeDateStart }
                              }),
                              _vm._v("\n                〜\n                "),
                              _c("a-date-picker", {
                                staticStyle: { width: "130px" },
                                attrs: {
                                  placeholder: "終了日",
                                  defaultValue: _vm.selectDateEndDefaultValue
                                },
                                on: { change: _vm.onChangeDateEnd }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("br"),
                      _vm.searchTarget !== 2 && _vm.myRole !== 3
                        ? _c(
                            "div",
                            _vm._l(_vm.freeItemUserOnlyDriveData, function(
                              item
                            ) {
                              return _c(
                                "a-form-item",
                                { key: item.id, attrs: { label: item.name } },
                                [
                                  _c("a-input", {
                                    model: {
                                      value: _vm.freeItemUserSearch[item.id],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.freeItemUserSearch,
                                          item.id,
                                          $$v
                                        )
                                      },
                                      expression: "freeItemUserSearch[item.id]"
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-item",
                        [
                          _c(
                            "a-button",
                            {
                              class: _vm.$style.filledBtn,
                              attrs: { type: "primary" },
                              on: { click: _vm.onSearchBtnPressed }
                            },
                            [_vm._v("検索")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.loading
                ? _c(
                    "div",
                    {
                      staticClass: "text-center",
                      staticStyle: {
                        "margin-top": "50px",
                        "margin-bottom": "50px"
                      }
                    },
                    [_c("a-spin", { attrs: { tip: "Loading..." } })],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _c("div", { staticClass: "clearfix" }, [
                    _c("p", { staticClass: "pull-left" }, [
                      _c("span", { class: _vm.$style.emNum }, [
                        _vm._v(_vm._s(_vm.driveDataTotal))
                      ]),
                      _c("span", { class: _vm.$style.description }, [
                        _vm._v("件 見つかりました")
                      ])
                    ]),
                    _c(
                      "p",
                      { staticClass: "pull-right" },
                      [
                        !_vm.loading
                          ? _c(
                              "a-button",
                              {
                                staticStyle: { "margin-right": "10px" },
                                on: { click: _vm.exportCsv }
                              },
                              [_vm._v("CSV 出力")]
                            )
                          : _vm._e(),
                        !_vm.loading && _vm.myCompanyPlan !== 6
                          ? _c(
                              "a-button",
                              {
                                staticStyle: { "margin-right": "10px" },
                                on: { click: _vm.exportCsvDetail }
                              },
                              [_vm._v("CSV 出力（詳細）")]
                            )
                          : _vm._e(),
                        _vm.myRole !== 3
                          ? _c(
                              "span",
                              [
                                !_vm.loading &&
                                _vm.myUseRawDownload &&
                                _vm.myRole <= 1
                                  ? _c(
                                      "a-button",
                                      {
                                        staticStyle: { "margin-right": "10px" },
                                        on: { click: _vm.exportRawZip }
                                      },
                                      [_vm._v("生データのダウンロード")]
                                    )
                                  : _vm._e(),
                                _vm.searchTarget === 3
                                  ? _c(
                                      "a-button",
                                      { on: { click: _vm.userAssign } },
                                      [_vm._v(_vm._s(_vm.userEditTitle))]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c("a-table", {
                    attrs: {
                      columns: _vm.columns,
                      "data-source": _vm.driveData,
                      pagination: false,
                      rowKey: "index_id",
                      rowClassName: function(r, i) {
                        return i % 2 === 0
                          ? _vm.$style.stripe1
                          : _vm.$style.stripe0
                      }
                    },
                    on: { change: _vm.sortChange },
                    scopedSlots: _vm._u([
                      {
                        key: "drive_time",
                        fn: function(id, record) {
                          return _c(
                            "span",
                            {},
                            [
                              _vm.searchTarget === 1
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "driveMapUser",
                                          params: {
                                            user_id: record.user_id,
                                            date: record.date
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm._f("momentDate")(record.date)
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.searchTarget === 2
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "driveMapVehicle",
                                          params: {
                                            vehicle_id: record.vehicle_id,
                                            date: record.date
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm._f("momentDate")(record.date)
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.searchTarget === 3
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "driveMap",
                                          params: { id: record.id }
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm._f("momentDate")(record.date)
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm._f("momentTime")(
                                              record.time_start
                                            )
                                          ) +
                                          " ~ " +
                                          _vm._s(
                                            _vm._f("momentTime")(
                                              record.time_end
                                            )
                                          ) +
                                          "\n                  "
                                      ),
                                      _vm.searchTarget === 3 &&
                                      record.analyze_status !== 0
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "5px"
                                              }
                                            },
                                            [
                                              record.analyze_status === 1
                                                ? _c("i", {
                                                    staticClass:
                                                      "fa fa-automobile",
                                                    staticStyle: {
                                                      color: "#fb6484"
                                                    }
                                                  })
                                                : _vm._e(),
                                              record.analyze_status === 2
                                                ? _c("i", {
                                                    staticClass:
                                                      "fa fa-tachometer",
                                                    staticStyle: {
                                                      color: "#42a2e9"
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "driver",
                        fn: function(id, record) {
                          return _c(
                            "span",
                            {},
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(record.user_name) +
                                  " "
                              ),
                              _vm.userEdit
                                ? _c(
                                    "a-button",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      on: {
                                        click: function($event) {
                                          return _vm.reassign(record.id)
                                        }
                                      }
                                    },
                                    [_vm._v("割当")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "vehicle_name",
                        fn: function(id, record) {
                          return _c("span", {}, [
                            _vm._v(
                              "\n                " +
                                _vm._s(record.vehicle_name) +
                                "\n              "
                            )
                          ])
                        }
                      },
                      {
                        key: "branch_name",
                        fn: function(id, record) {
                          return _c("span", {}, [
                            _vm._v(
                              "\n                " +
                                _vm._s(record.branch_name) +
                                "\n              "
                            )
                          ])
                        }
                      },
                      {
                        key: "complianceRate",
                        fn: function(id, record) {
                          return _c("span", {}, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  record.regulation_num
                                    ? record.compliance_rate + "%"
                                    : "-"
                                ) +
                                "\n              "
                            )
                          ])
                        }
                      },
                      {
                        key: "distance",
                        fn: function(id, record) {
                          return _c(
                            "span",
                            {},
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(record.distance / 1000) +
                                  "\n                "
                              ),
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    title: "本当に削除しますか?",
                                    okText: "削除",
                                    okType: "danger",
                                    cancelText: "取消"
                                  },
                                  on: {
                                    confirm: function($event) {
                                      return _vm.deleteDriveDataItem(record.id)
                                    }
                                  }
                                },
                                [
                                  _vm.myRole === 0 && _vm.userEdit
                                    ? _c(
                                        "a-button",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("削除")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }
                      }
                    ])
                  }),
                  _c("a-pagination", {
                    staticStyle: {
                      "text-align": "right",
                      "margin-top": "20px"
                    },
                    attrs: {
                      total: _vm.pageTotal,
                      defaultPageSize: _vm.defaultPageSize
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.pageCurrent,
                      callback: function($$v) {
                        _vm.pageCurrent = $$v
                      },
                      expression: "pageCurrent"
                    }
                  }),
                  _c(
                    "a-modal",
                    {
                      attrs: {
                        title: "運転者割当",
                        visible: _vm.visibleAssign,
                        "confirm-loading": _vm.confirmLoading,
                        okText: "割り当てる",
                        okType: "primary",
                        cancelText: "キャンセル"
                      },
                      on: {
                        ok: _vm.handleOkAssign,
                        cancel: _vm.handleCancelAssign
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("p", [_vm._v("運転者に割り当てるユーザー")]),
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "show-search": "",
                                placeholder: "乗車するユーザーを選択",
                                "option-filter-prop": "children",
                                value: _vm.assignedUser,
                                "filter-option": _vm.filterOption,
                                "default-value": _vm.assignedUser
                              },
                              on: { change: _vm.userHandleChange }
                            },
                            _vm._l(_vm.users, function(user) {
                              return _c(
                                "a-select-option",
                                { key: user.id, attrs: { value: user.id } },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(user.name) +
                                      "\n                  "
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }