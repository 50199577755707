<template>
  <div>
    <h2>運行履歴</h2>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div style="margin-bottom: 20px">
              <span :class="$style.filterLabel">閲覧対象:</span>
              <a-radio-group button-style="solid" v-model="searchTarget" :options="radioOptions" @change="changeTarget" />
            </div>
            <span class="mr-5" :class="$style.filterHeader">フィルター</span>
            <a-form v-if="filterLoading === 3" layout="inline" style="margin-bottom: 20px;">
              <a-form-item v-if="searchTarget !== 2 && myRole !== 3 && myCompanyPlan !== 4" label="運転者">
                <form-multiple-select
                  placeholder="運転者"
                  :candidates="users"
                  :styles="selectStyle"
                  :disabled="selectUserDisabled"
                  :defaultValue="selectUserDefaultValue"
                  @changeSelectedItems="changeSelectedUsers"
                />
              </a-form-item>
              <a-form-item v-if="searchTarget !== 1" label="支店名">
                <form-multiple-select
                  placeholder="支店名"
                  :candidates="branches"
                  :styles="selectStyle"
                  :disabled="selectBranchDisabled"
                  :defaultValue="selectBranchDefaultValue"
                  @changeSelectedItems="changeSelectedBranches"
                />
              </a-form-item>
              <a-form-item v-if="searchTarget !== 1" label="車両">
                <form-multiple-select
                  placeholder="車両"
                  :candidates="vehicles"
                  :styles="selectStyle"
                  :disabled="selectVehicleDisabled"
                  :defaultValue="selectVehicleDefaultValue"
                  @changeSelectedItems="changeSelectedVehicles"
                />
              </a-form-item>
              <a-config-provider :locale="locale">
                <a-form-item label="対象期間">
                  <a-date-picker :locale="locale" :defaultValue="selectDateStartDefaultValue" placeholder="開始日" @change="onChangeDateStart" style="width: 130px;" />
                  〜
                  <a-date-picker placeholder="終了日" :defaultValue="selectDateEndDefaultValue" @change="onChangeDateEnd" style="width: 130px;" />
                </a-form-item>
              </a-config-provider><br>
              <div v-if="searchTarget !== 2 && myRole !== 3">
                <a-form-item v-for="item in freeItemUserOnlyDriveData" :key="item.id" :label="item.name">
                  <a-input v-model="freeItemUserSearch[item.id]" />
                </a-form-item>
              </div>
              <a-form-item>
                <a-button @click="onSearchBtnPressed" type="primary" :class="$style.filledBtn">検索</a-button>
              </a-form-item>
            </a-form>
            <div v-if="loading" class="text-center" style="margin-top: 50px; margin-bottom: 50px;">
              <a-spin tip="Loading..." />
            </div>
            <div>
              <div class="clearfix">
                <p class="pull-left"><span :class="$style.emNum">{{ driveDataTotal }}</span> <span :class="$style.description">件 見つかりました</span></p>
                <p class="pull-right">
                  <a-button v-if="!loading" @click="exportCsv" style="margin-right: 10px;">CSV 出力</a-button>
                  <a-button v-if="!loading && myCompanyPlan !== 6" @click="exportCsvDetail" style="margin-right: 10px;">CSV 出力（詳細）</a-button>
                  <span v-if="myRole !== 3">
                    <a-button v-if="!loading && myUseRawDownload && myRole <= 1" @click="exportRawZip" style="margin-right: 10px;">生データのダウンロード</a-button>
                    <a-button v-if="searchTarget === 3" @click="userAssign">{{ userEditTitle }}</a-button>
                  </span>
                </p>
              </div>
              <a-table
                :columns="columns"
                :data-source="driveData"
                :pagination="false"
                rowKey="index_id"
                @change="sortChange"
                :rowClassName="(r, i) => i % 2 === 0 ? $style.stripe1 :  $style.stripe0"
              >
                <span slot="drive_time" slot-scope="id, record">
                  <router-link v-if="searchTarget === 1" :to="{ name: 'driveMapUser', params: { user_id: record.user_id, date: record.date }}">
                    {{ record.date | momentDate }}
                  </router-link>
                  <router-link v-if="searchTarget === 2" :to="{ name: 'driveMapVehicle', params: { vehicle_id: record.vehicle_id, date: record.date }}">
                    {{ record.date | momentDate }}
                  </router-link>
                  <router-link v-if="searchTarget === 3" :to="{ name: 'driveMap', params: { id: record.id }}">
                    {{ record.date | momentDate }} {{ record.time_start | momentTime }} ~ {{ record.time_end | momentTime }}
                    <span v-if="searchTarget === 3 && record.analyze_status !== 0" style="margin-left: 5px;">
                      <i v-if="record.analyze_status === 1" class="fa fa-automobile" style="color: #fb6484;"></i>
                      <i v-if="record.analyze_status === 2" class="fa fa-tachometer" style="color: #42a2e9;"></i>
                    </span>
                  </router-link>
                </span>
                <span slot="driver" slot-scope="id, record">
                  {{ record.user_name }} <a-button style="margin-left: 10px;" v-if="userEdit" @click="reassign(record.id)">割当</a-button>
                </span>
                <span slot="vehicle_name" slot-scope="id, record">
                  {{ record.vehicle_name }}
                </span>
                <span slot="branch_name" slot-scope="id, record">
                  {{ record.branch_name }}
                </span>
                <span slot="complianceRate" slot-scope="id, record">
                  {{ record.regulation_num ? record.compliance_rate + '%' : '-' }}
                </span>
                <span slot="distance" slot-scope="id, record">
                  {{ record.distance / 1000 }}
                  <a-popconfirm
                    title="本当に削除しますか?"
                    okText="削除"
                    okType="danger"
                    cancelText="取消"
                    @confirm="deleteDriveDataItem(record.id)"
                  >
                    <a-button v-if="myRole === 0 && userEdit" type="danger">削除</a-button>
                  </a-popconfirm>
                </span>
              </a-table>
              <a-pagination v-model="pageCurrent" :total="pageTotal" @change="pageChange" :defaultPageSize="defaultPageSize" style="text-align: right; margin-top: 20px;" />
              <a-modal
                title="運転者割当"
                :visible="visibleAssign"
                :confirm-loading="confirmLoading"
                okText='割り当てる'
                okType='primary'
                cancelText='キャンセル'
                @ok="handleOkAssign"
                @cancel="handleCancelAssign"
              >
                <div>
                  <p>運転者に割り当てるユーザー</p>
                  <a-select
                    show-search
                    placeholder="乗車するユーザーを選択"
                    option-filter-prop="children"
                    style="width: 100%"
                    :value="assignedUser"
                    :filter-option="filterOption"
                    :default-value="assignedUser"
                    @change="userHandleChange"
                  >
                    <a-select-option
                      v-for="user in users"
                      :key="user.id"
                      :value="user.id"
                    >
                      {{ user.name }}
                    </a-select-option>
                  </a-select>
                </div>
              </a-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<script>
import Vue from 'vue'
import moment from 'moment'
import storeVue from '@/store'
import { enumData } from '@/services/enum'
import FormMultipleSelect from '../../../components/FormComponents/MultipleSelect'
import jaJa from 'ant-design-vue/es/locale/ja_JP'
import 'moment/locale/ja'

moment.locale('ja')

const options = [
  { label: '運転者', value: 1 },
  { label: '車両', value: 2 },
  { label: '運行', value: 3 },
]

const optionsNoUser = [
  { label: '車両', value: 2 },
  { label: '運行', value: 3 },
]

export default {
  components: {
    FormMultipleSelect,
  },
  data() {
    return {
      locale: jaJa,
      query: {},
      radioOptions: options,
      typeOptions: enumData.violationType,
      roadOptions: enumData.roadType,

      columnsBase: [
        {
          title: '運転日',
          dataIndex: 'date',
          dataType: 'default',
          scopedSlots: { customRender: 'drive_time' },
          sorter: true,
          defaultSortOrder: 'descend',
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: '支店名',
          dataIndex: 'branch_name',
          dataType: 'default',
          scopedSlots: { customRender: 'branch_name' },
          sorter: true,
        },
        {
          title: '運転者',
          dataIndex: 'user_name',
          dataType: 'default',
          scopedSlots: { customRender: 'driver' },
          sorter: true,
          defaultSortOrder: 'descend',
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: '車両',
          dataIndex: 'vehicle_name',
          dataType: 'default',
          scopedSlots: { customRender: 'vehicle_name' },
          sorter: true,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: '遵守率',
          dataIndex: 'compliance_rate',
          dataType: 'default',
          sorter: false,
          scopedSlots: { customRender: 'complianceRate' },
        },
        {
          title: '違反件数',
          dataIndex: 'violation_count',
          dataType: 'default',
          sorter: false,
        },
        {
          title: '運転時間',
          dataIndex: 'driving_time',
          dataType: 'default',
          sorter: false,
        },
        {
          title: '走行距離(km)',
          dataIndex: 'distance',
          dataType: 'default',
          sorter: false,
          scopedSlots: { customRender: 'distance' },
        },
      ],
      options,
      userEdit: false,
      userEditTitle: '運転者再割当',
      selectStyle: 'min-width:130px',
      loading: true,
      filterLoading: 0,
      driveDataTotal: 0,
      searchTarget: 1,
      pageTotal: 1,
      pageCurrent: 1,
      defaultPageSize: 1,
      sortItem: null,
      sortOrder: null,
      driveData: [],
      branches: [],
      users: [],
      vehicles: [],
      selectedBranches: [],
      selectedUsers: [],
      selectedVehicles: [],
      selectedDateStart: '',
      selectedDateEnd: '',
      selectBranchDefaultValue: null,
      selectBranchDisabled: false,
      selectUserDefaultValue: null,
      selectUserDisabled: false,
      selectVehicleDefaultValue: null,
      selectVehicleDisabled: false,
      selectDateStartDefaultValue: null,
      selectDateEndDefaultValue: null,
      searchDriveDataPostData: null,
      searchPath: null,
      freeItemUserList: [],
      freeItemUserOnlyDriveData: [],
      freeItemUserSearch: {},

      visibleAssign: false,
      confirmLoading: false,
      assignedUser: null,
      targetDD: null,
      drive_data_ids: [],

      myRole: 3,
      myCompanyPlan: 3,
      myUseAnalyze: 1,
      myUseRawDownload: 0,
      concatVehicleNameAndNo: 1,

      orderTranslate: { ascend: 'asc', descend: 'desc' },
      orderTranslateInverse: { asc: 'ascend', desc: 'descend' },
      itemTranslate: {
        date: 'drive_data.date',
        vehicle_name: 'vehicles.name',
        branch_name: 'branches.name',
        user_name: 'users.name',
      },
    }
  },
  props: [
    'target',
    'targetId',
  ],
  computed: {
    columns: function() {
      return this.columnsBase.filter((item) => {
        if (this.myCompanyPlan === 6) {
          if (item.title === '遵守率' || item.title === '違反件数') return false
        }
        if (this.searchTarget === 1) {
          return (item.title !== '車両' && item.title !== '支店名')
        } else if (this.searchTarget === 2 || this.myCompanyPlan === 4) {
          return (item.title !== '運転者' && item.dataType !== 'freeItemUser')
        } else if (this.searchTarget === 3) {
          return true
        }
      })
    },
  },
  watch: {
    myCompanyPlan: function(val) {
      if (val === 4) {
        this.searchTarget = 2
        this.radioOptions = optionsNoUser
        this.searchDriveData()
      }
    },
  },
  created() {
    const _this = this
    this.myCompanyPlan = storeVue.getters.company_plan
    this.myRole = storeVue.getters.role
    this.myUseAnalyze = storeVue.getters.use_analyze
    if (this.myCompanyPlan === 4) {
      this.radioOptions = optionsNoUser
      this.searchTarget = 2
    } else {
      this.radioOptions = options
      this.searchTarget = 1
    }
    const myResult = Vue.prototype.$api.send('get', 'user')
    myResult.then(response => {
      _this.myUseAnalyze = response.use_analyze
      _this.myUseRawDownload = response.use_raw_download
      _this.myRole = response.role
      _this.myCompanyPlan = response.company_plan
      _this.concatVehicleNameAndNo = response.concat_vehicle_name_and_no
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 自身の情報取得に失敗しました。',
        })
      })
    if (this.myRole !== 3) {
      const usersResult = Vue.prototype.$api.send('get', 'users')
      usersResult.then(response => {
        this.users = response
        this.filterLoading++
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': ユーザーの取得に失敗しました。',
          })
        })
    } else {
      this.filterLoading++
    }

    const freeItemUserResult = Vue.prototype.$api.send('get', 'free_item_users')
    freeItemUserResult.then(response => {
      this.freeItemUserList = response
      var index = 2
      response.map(v => {
        if (v.hide_drive_data_list === 0) {
          var columnContent = {
            title: v.name,
            dataIndex: 'freeItemUser' + v.id,
            dataType: 'freeItemUser',
            sorter: false,
          }
          _this.columnsBase.splice(index, 0, columnContent)
          index++
          const searchData = {
            id: v.id,
            name: v.name,
          }
          _this.freeItemUserOnlyDriveData.push(searchData)
          if (_this.query['freeItem' + v.id]) _this.freeItemUserSearch[v.id] = _this.query['freeItem' + v.id]
        }
      })
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ユーザーの自由項目の取得に失敗しました。',
        })
        this.freeItemUserLoading = false
      })
  },
  beforeMount() {
    if (this.target) {
      if (this.target === 'branch') {
        this.selectedBranches = [this.targetId]
        this.selectBranchDisabled = true
        this.selectBranchDefaultValue = [this.targetId]
      } else if (this.target === 'user') {
        this.selectedUsers = [this.targetId]
        this.selectUserDisabled = true
        this.selectUserDefaultValue = [this.targetId]
      } else if (this.target === 'vehicle') {
        this.searchTarget = 2
        this.selectedVehicles = [this.targetId]
        this.selectVehicleDisabled = true
        this.selectVehicleDefaultValue = [this.targetId]
      }
    }
    this.extractURLQueryParams(this.$route.query)
    this.searchDriveData()

    const branchesResult = Vue.prototype.$api.send('get', 'user/branches')
    branchesResult.then(response => {
      this.branches = response
      this.filterLoading++
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
      })

    const vehicleResult = Vue.prototype.$api.send('get', 'user/dashboard_vehicles')
    vehicleResult.then(response => {
      this.vehicles = response.map((item) => {
        if (this.concatVehicleNameAndNo && item.number) item.name = item.name + ',' + item.number
        return item
      })
      this.filterLoading++
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 車両の取得に失敗しました。',
        })
      })
  },
  beforeRouteUpdate(to, from, next) {
    this.extractURLQueryParams(to.query)
    this.searchDriveData()
    next()
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('YYYY/MM/DD')
    },
    momentTime: function (time) {
      return moment(time, 'YYYY-MM-DD hh:mm:ss').format('HH:mm')
    },
  },
  methods: {
    changeSelectedBranches(selectedItems) { this.selectedBranches = selectedItems },
    changeSelectedUsers(selectedItems) { this.selectedUsers = selectedItems },
    changeSelectedVehicles(selectedItems) { this.selectedVehicles = selectedItems },
    onChangeDateStart(date, dateString) { this.selectedDateStart = dateString },
    onChangeDateEnd(date, dateString) { this.selectedDateEnd = dateString },
    pageChange(current) {
      this.pageCurrent = current
      this.confirmSearch()
    },
    onSearchBtnPressed() {
      this.pageCurrent = 1
      this.confirmSearch()
    },
    searchDriveData() {
      const _this = this
      this.loading = true
      this.drive_data_ids = []
      if (this.searchTarget === 1) { // user
        this.searchDriveDataPostData = {
          users: this.selectedUsers,
          dateStart: this.selectedDateStart,
          dateEnd: this.selectedDateEnd,
          sortItem: this.sortItem,
          sortOrder: this.orderTranslate[this.sortOrder],
          page: this.pageCurrent,
        }
        this.searchPath = 'drive_data/user'
      } else if (this.searchTarget === 2) { // vehicle
        this.searchDriveDataPostData = {
          branches: this.selectedBranches,
          vehicles: this.selectedVehicles,
          dateStart: this.selectedDateStart,
          dateEnd: this.selectedDateEnd,
          sortItem: this.sortItem,
          sortOrder: this.orderTranslate[this.sortOrder],
          page: this.pageCurrent,
        }
        this.searchPath = 'drive_data/vehicle'
      } else if (this.searchTarget === 3) { // drive_data
        this.searchDriveDataPostData = {
          users: this.selectedUsers,
          branches: this.selectedBranches,
          vehicles: this.selectedVehicles,
          dateStart: this.selectedDateStart,
          dateEnd: this.selectedDateEnd,
          sortItem: this.sortItem,
          sortOrder: this.orderTranslate[this.sortOrder],
          page: this.pageCurrent,
        }
        this.searchPath = 'drive_data'
      }
      if (this.searchTarget !== 2) {
        this.searchDriveDataPostData.freeItemUserContents = this.freeItemUserSearch
      }
      const result = Vue.prototype.$api.send('get', this.searchPath, this.searchDriveDataPostData, 1)
      result.then(response => {
        let indexId = 1
        this.driveData = response.data.map((item) => {
          item.name = item.vehicle_name
          if (this.concatVehicleNameAndNo && item.number) item.vehicle_name = item.vehicle_name + ',' + item.number
          item.index_id = indexId
          indexId++
          item.driving_time = ('00' + Math.floor(item.driving_time / 3600)).slice(-2) + ':' + ('00' + Math.floor(item.driving_time / 60) % 60).slice(-2) + ':' + ('00' + item.driving_time % 60).slice(-2)
          if (_this.myUseAnalyze === 0) {
            if (item.announce_count === 0) {
              item.compliance_rate = 100
            } else {
              item.compliance_rate = (((item.announce_count - item.violation_count) / item.announce_count) * 100).toFixed(0)
            }
          }
          if (_this.searchTarget !== 2 && item.free_item_user_contents && item.free_item_user_contents.data) {
            item.free_item_user_contents.data.map((fiuc) => {
              item['freeItemUser' + fiuc.free_item_user_id] = fiuc.data
            })
          }

          if (_this.searchTarget === 3) _this.drive_data_ids.push(item.id)
          else {
            item.id.split(',').map((ddid) => {
              _this.drive_data_ids.push(ddid)
            })
          }
          return item
        })
        this.pageTotal = response.meta.last_page
        this.driveDataTotal = response.meta.total
        this.loading = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 運行履歴の取得に失敗しました。',
          })
          this.loading = false
        })
    },
    sortChange(pagination, filters, sorter) {
      this.sortItem = sorter.columnKey ? this.itemTranslate[sorter.columnKey] : null
      this.sortOrder = sorter.order ? sorter.order : null
      this.confirmSearch()
    },
    userAssign() {
      this.userEdit = !this.userEdit
      if (this.userEdit) this.userEditTitle = '割当終了'
      else this.userEditTitle = '運転者再割当'
    },
    handleCancelAssign() { this.visibleAssign = false },
    userHandleChange(value, obj) {
      this.assignedUser = value
    },
    handleOkAssign() {
      const _this = this
      this.confirmLoading = true
      const postData = {
        user_id: this.assignedUser,
      }
      const result = Vue.prototype.$api.send('put', 'drive_data/' + this.targetDD.id + '/assign_user', postData)
      result.then(response => {
        const assignUser = _this.users.find((e) => e.id === _this.assignedUser)
        this.targetDD.user_id = assignUser.id
        this.targetDD.user_name = assignUser.name
        _this.$notification['success']({
          message: '運転者割当をしました。',
        })
        this.confirmLoading = false
        this.visibleAssign = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 運転者再割当てに失敗しました。',
          })
          this.loading = false
        })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
      )
    },
    reassign(ddId) {
      this.visibleAssign = true
      this.targetDD = this.driveData.find((e) => e.id === ddId)
      if (this.targetDD.user_id) {
        this.assignedUser = this.targetDD.user_id
      } else {
        this.assignedUser = null
      }
    },
    exportCsv() {
      let csv = '\ufeff'
      csv += '運転日,'
      if (this.searchTarget === 1) {
        this.freeItemUserList.map((v) => {
          csv += v.name + ','
        })
        csv += '運転者'
      } else if (this.searchTarget === 2) {
        csv += '支店名,車両,車両番号'
      } else if (this.searchTarget === 3) {
        csv += '支店名,'
        this.freeItemUserList.map((v) => {
          csv += v.name + ','
        })
        csv += '運転者,車両,車両番号,運転開始日時,運転終了日時'
      }
      csv += ',走行距離(km),運転時間'
      if (this.myCompanyPlan !== 6) {
        csv += ',違反件数,遵守率(%)'
      }
      csv += '\n'
      if (this.searchDriveDataPostData.dateStart && this.searchDriveDataPostData.dateEnd) {
        const _this = this
        this.loading = true
        const result = Vue.prototype.$api.send('get', this.searchPath + '/export', this.searchDriveDataPostData, 1)
        result.then(response => {
          let indexId = 1
          const driveData = response.data.map((item) => {
            item.index_id = indexId
            indexId++
            item.driving_time = ('00' + Math.floor(item.driving_time / 3600)).slice(-2) + ':' + ('00' + Math.floor(item.driving_time / 60) % 60).slice(-2) + ':' + ('00' + item.driving_time % 60).slice(-2)
            if (_this.myUseAnalyze === 0) {
              item.regulation_num = item.announce_count
              if (item.announce_count === 0) {
                item.compliance_rate = 100
              } else {
                item.compliance_rate = (((item.announce_count - item.violation_count) / item.announce_count) * 100).toFixed(2)
              }
            } else {
              if (item.regulation_num === '0') {
                item.compliance_rate = 100
              } else {
                item.compliance_rate = (((item.regulation_num - item.violation_count) / item.regulation_num) * 100).toFixed(2)
              }
            }
            if (_this.searchTarget !== 2 && item.free_item_user_contents.data) {
              item.free_item_user_contents.data.map((fiuc) => {
                item['freeItemUser' + fiuc.free_item_user_id] = fiuc.data
              })
            }
            return item
          })
          driveData.forEach(dd => {
            let line = dd['date'] + ','
            if (this.searchTarget === 1) {
              this.freeItemUserList.map((v) => {
                if (dd['freeItemUser' + v.id]) line += dd['freeItemUser' + v.id]
                line += ','
              })
              line += dd['user_name']
            } else if (this.searchTarget === 2) {
              line += dd['branch_name'] + ',' + dd['vehicle_name'] + ',' + dd['number']
            } else if (this.searchTarget === 3) {
              line += dd['branch_name'] + ','
              this.freeItemUserList.map((v) => {
                if (dd['freeItemUser' + v.id]) line += dd['freeItemUser' + v.id]
                line += ','
              })
              line += dd['user_name'] + ',' + dd['vehicle_name'] + ',' + dd['number'] + ',' + dd['time_start'] + ',' + dd['time_end']
            }
            line += ',' + dd['distance'] / 1000 + ',' + dd['driving_time']
            if (this.myCompanyPlan !== 6) {
              line += ',' + dd['violation_count'] + ',' + dd['compliance_rate']
            }
            line += '\n'
            csv += line
          })
          var blob = new Blob([csv], { type: 'text/plain' })
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'drive_data.csv'
          link.click()
          this.loading = false
        })
          .catch(error => {
            console.log('drive_data_error', error)
            this.$notification['error']({
              message: error.status + ': CSV出力に失敗しました。',
            })
            this.loading = false
          })
      } else {
        this.driveData.forEach(dd => {
          let line = dd['date'] + ','
          if (this.searchTarget === 1) {
            this.freeItemUserList.map((v) => {
              if (dd['freeItemUser' + v.id]) line += dd['freeItemUser' + v.id]
              line += ','
            })
            line += dd['user_name']
          } else if (this.searchTarget === 2) {
            line += dd['branch_name'] + ',' + dd['name'] + ',' + dd['number']
          } else if (this.searchTarget === 3) {
            line += dd['branch_name'] + ','
            this.freeItemUserList.map((v) => {
              if (dd['freeItemUser' + v.id]) line += dd['freeItemUser' + v.id]
              line += ','
            })
            line += dd['user_name'] + ',' + dd['name'] + ',' + dd['number'] + ',' + dd['time_start'] + ',' + dd['time_end']
          }
          line += ',' + dd['distance'] / 1000 + ',' + dd['driving_time']
          if (this.myCompanyPlan !== 6) {
            line += ',' + dd['violation_count'] + ',' + dd['compliance_rate']
          }
          line += '\n'
          csv += line
        })
        var blob = new Blob([csv], { type: 'text/plain' })
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'drive_data.csv'
        link.click()
      }
    },
    exportCsvDetail() {
      if (this.myCompanyPlan === 6) return
      const _this = this
      this.loading = true
      const result = Vue.prototype.$api.send('get', this.searchPath + '/export', this.searchDriveDataPostData, 1)
      result.then(response => {
        let indexId = 1
        // if (!this.searchDriveDataPostData.dateStart || !this.searchDriveDataPostData.dateEnd) response = response.data
        const driveData = response.data.map((item) => {
          item.index_id = indexId
          indexId++
          item.driving_time = ('00' + Math.floor(item.driving_time / 3600)).slice(-2) + ':' + ('00' + Math.floor(item.driving_time / 60) % 60).slice(-2) + ':' + ('00' + item.driving_time % 60).slice(-2)
          if (_this.myUseAnalyze === 0) {
            item.regulation_num = item.announce_count
            if (item.announce_count === 0) {
              item.compliance_rate = 100
            } else {
              item.compliance_rate = (((item.announce_count - item.violation_count) / item.announce_count) * 100).toFixed(0)
            }
          }
          if (_this.searchTarget !== 2 && item.free_item_user_contents) {
            item.free_item_user_contents.data.map((fiuc) => {
              item['freeItemUser' + fiuc.free_item_user_id] = fiuc.data
            })
          }
          return item
        })

        let csv = '\ufeff'
        csv += '運転日,'
        if (this.searchTarget !== 2) {
          this.freeItemUserList.map((v) => {
            csv += v.name + ','
          })
        }
        if (this.searchTarget === 1) csv += '運転者,'
        else if (this.searchTarget === 2) csv += '支店名,車両,車両番号,'
        else if (this.searchTarget === 3) csv += '運転者,支店名,車両,車両番号,'
        csv += '走行距離(km),運転時間,違反件数,規制件数,開始時刻,終了時刻,発生日,発生時刻,違反行為の種類,コメント,道路種別,経度,緯度,道路名称,制限速度,規制数：一時停止,規制数：最高速度,規制数：踏切,規制数：一方通行,規制数：指定方向外進行禁止,規制数（細道路以外）：一時停止,規制数（細道路以外）：最高速度,規制数（細道路以外）：踏切,規制数（細道路以外）：一方通行,規制数（細道路以外）：指定方向外進行禁止,規制数（細道路）：一時停止,規制数（細道路）：最高速度,規制数（細道路）：踏切,規制数（細道路）：一方通行,規制数（細道路）：指定方向外進行禁止\n'

        driveData.forEach(dd => {
          dd['violations'].forEach(violation => {
            const violationType = _this.typeOptions.find(target => target.value === violation['type']).label
            const roadType = _this.roadOptions.find(target => target.value === violation['place']).label
            var violationDateTimeMoment = moment(violation['date'] + ' ' + violation['time']).add(9, 'hours')
            let line = dd['date'] + ','
            if (this.searchTarget !== 2) {
              this.freeItemUserList.map((v) => {
                if (dd['freeItemUser' + v.id]) line += dd['freeItemUser' + v.id]
                line += ','
              })
            }
            if (this.searchTarget === 1) line += dd['user_name']
            else if (this.searchTarget === 2) line += dd['branch_name'] + ',' + dd['vehicle_name'] + ',' + dd['number']
            else if (this.searchTarget === 3) line += dd['user_name'] + ',' + dd['branch_name'] + ',' + dd['vehicle_name'] + ',' + dd['number']
            line += ',' + dd['distance'] / 1000 + ',' + dd['driving_time'] + ',' + dd['violation_count'] + ',' + dd['regulation_num'] + ',' + dd['time_start_min'] + ',' + dd['time_end_max'] + ',' + violationDateTimeMoment.format('YYYY/MM/DD') + ',' + violationDateTimeMoment.format('HH:mm:ss') + ',' + violationType + ',' + violation['description'] + ',' + roadType + ',' + violation['longitude'] + ',' + violation['latitude'] + ',' + violation['place_name'] + ',' + violation['limit_speed'] + ',' + dd['regulation_temporary_stop_num'] + ',' + dd['regulation_maximum_speed_num'] + ',' + dd['regulation_railroad_stop_num'] + ',' + dd['regulation_oneway_num'] + ',' + dd['regulation_specified_direction_num'] + ',' + dd['regulation_general_temporary_stop_num'] + ',' + dd['regulation_general_maximum_speed_num'] + ',' + dd['regulation_general_railroad_stop_num'] + ',' + dd['regulation_general_oneway_num'] + ',' + dd['regulation_general_specified_direction_num'] + ',' + dd['regulation_narrow_temporary_stop_num'] + ',' + dd['regulation_narrow_maximum_speed_num'] + ',' + dd['regulation_narrow_railroad_stop_num'] + ',' + dd['regulation_narrow_oneway_num'] + ',' + dd['regulation_narrow_specified_direction_num'] + '\n'
            csv += line
          })
        })
        var blob = new Blob([csv], { type: 'text/plain' })
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'violation_list.csv'
        link.click()
        this.loading = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': CSV出力（詳細）に失敗しました。',
          })
          this.loading = false
        })
    },
    changeTarget() {
      this.sortItem = null
      this.sortOrder = null
      this.pageCurrent = 1
      this.confirmSearch()
    },
    exportRawZip() {
      this.loading = true
      const postData = {
        drive_data_ids: this.drive_data_ids,
        concat_type: this.searchTarget % 3,
      }
      const result = Vue.prototype.$api.send('post', 'drive_data/raw_zip', postData, 1, 'blob')
      result.then(response => {
        var blob = new Blob([response], { type: 'application/zip' })
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'RMCraw.zip'
        link.click()
        this.loading = false
      })
    },
    extractURLQueryParams(query) {
      this.query = query
      if (query.searchTarget) {
        const searchTargetInt = parseInt(query.searchTarget)
        if (!isNaN(searchTargetInt) && searchTargetInt >= 1 && searchTargetInt <= 3) {
          this.searchTarget = searchTargetInt
        } else {
          this.$notification['error']({
            message: '無効な閲覧対象です',
          })
        }
      }
      if (this.searchTarget === 1 || this.searchTarget === 3) {
        if (query.users && !this.selectUserDisabled) {
          const queryUserIds = query.users.map(str => parseInt(str)).filter((id) => !isNaN(id))
          this.selectedUsers = queryUserIds
          this.selectUserDefaultValue = queryUserIds
        }
        for (const key in this.freeItemUserOnlyDriveData) {
          const id = this.freeItemUserOnlyDriveData[key].id
          if (query['freeItem' + id]) this.freeItemUserSearch[id] = query['freeItem' + id]
        }
      }
      if (this.searchTarget === 2 || this.searchTarget === 3) {
        if (query.branches && !this.selectBranchDisabled) {
          const queryBranchIds = query.branches.map(str => parseInt(str)).filter((id) => !isNaN(id))
          this.selectedBranches = queryBranchIds
          this.selectBranchDefaultValue = queryBranchIds
        }
        if (query.vehicles && !this.selectVehicleDisabled) {
          const queryVehicleIds = query.vehicles.map(str => parseInt(str)).filter((id) => !isNaN(id))
          this.selectedVehicles = queryVehicleIds
          this.selectVehicleDefaultValue = queryVehicleIds
        }
      }
      if (query.dateStart) {
        if (moment(query.dateStart).isValid()) {
          this.selectedDateStart = query.dateStart
          this.selectDateStartDefaultValue = query.dateStart
        } else {
          this.$notification['error']({
            message: '無効な開始日付です',
          })
          this.selectedDateStart = null
          this.selectDateStartDefaultValue = null
        }
      } else {
        this.selectedDateStart = null
        this.selectDateStartDefaultValue = null
      }
      if (query.dateEnd) {
        if (moment(query.dateEnd).isValid()) {
          this.selectedDateEnd = query.dateEnd
          this.selectDateEndDefaultValue = query.dateEnd
        } else {
          this.$notification['error']({
            message: '無効な終了日付です',
          })
          this.selectedDateEnd = null
          this.selectDateEndDefaultValue = null
        }
      } else {
        this.selectedDateEnd = null
        this.selectDateEndDefaultValue = null
      }
      if (query.page) {
        const pageInt = parseInt(query.page)
        if (!isNaN(pageInt) && pageInt > 0) {
          this.pageCurrent = pageInt
        } else {
          this.$notification['error']({
            message: '無効なページです',
          })
        }
      }
      if (query.sortItem) {
        const sortItem = Object.entries(this.itemTranslate).find((item) => item[1] === query.sortItem)
        this.sortOrder = query.sortOrder === 'ascend' || query.sortOrder === 'descend' ? query.sortOrder : null
        if (sortItem) {
          this.sortItem = sortItem[1]
        } else {
          this.$notification['error']({
            message: '無効なソート項目です',
          })
        }
        if (this.sortOrder) {
          this.columnsBase.forEach((obj, index) => { this.columnsBase[index].sortOrder = this.itemTranslate[obj.dataIndex] === this.sortItem ? this.sortOrder : null })
        } else {
          this.columnsBase.forEach((obj, index) => { this.columnsBase[index].sortOrder = null })
        }
      }
    },
    confirmSearch() { // URL にクエリのパラメータをセットする
      const query = {}
      if (this.searchTarget === 1 && !this.selectUserDisabled) { // user
        query.users = this.selectedUsers
      } else if (this.searchTarget === 2) { // vehicle
        if (!this.selectBranchDisabled) {
          query.branches = this.selectedBranches
        }
        if (!this.selectVehicleDisabled) {
          query.vehicles = this.selectedVehicles
        }
      } else if (this.searchTarget === 3) { // drive_data
        if (!this.selectUserDisabled) {
          query.users = this.selectedUsers
        }
        if (!this.selectBranchDisabled) {
          query.branches = this.selectedBranches
        }
        if (!this.selectVehicleDisabled) {
          query.vehicles = this.selectedVehicles
        }
      }
      if (this.searchTarget !== 2) {
        for (const key in this.freeItemUserSearch) {
          if (this.freeItemUserSearch[key]) query['freeItem' + key] = this.freeItemUserSearch[key]
        }
      }
      query.dateStart = this.selectedDateStart
      query.dateEnd = this.selectedDateEnd
      query.sortItem = this.sortItem
      query.sortOrder = this.orderTranslateInverse[this.sortOrder]
      query.page = this.pageCurrent
      query.searchTarget = this.searchTarget
      query.sortOrder = this.sortOrder
      this.$router.push({ query: query }).catch(err => {
        if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
          console.error(err)
        }
      })
    },
    deleteDriveDataItem(id) {
      const myResult = Vue.prototype.$api.send('delete', 'drive_data/' + id)
      myResult.then(response => {
        this.$notification['success']({ message: '運行履歴を削除しました。' })
        this.driveData = this.driveData.filter(e => e.id !== id)
      }).catch(err => {
        this.$notification['error']({ message: `${err.status}: 運行履歴削除に失敗しました。` })
      })
    },
  },
}
</script>

<style>
  .ant-calendar-header .ant-calendar-prev-month-btn::before,
  .ant-calendar-header .ant-calendar-prev-month-btn::after,
  .ant-calendar-header .ant-calendar-prev-century-btn::before,
  .ant-calendar-header .ant-calendar-prev-decade-btn::before,
  .ant-calendar-header .ant-calendar-prev-year-btn::before,
  .ant-calendar-header .ant-calendar-prev-century-btn::after,
  .ant-calendar-header .ant-calendar-prev-decade-btn::after,
  .ant-calendar-header .ant-calendar-prev-year-btn::after,
  .ant-calendar-header .ant-calendar-next-month-btn::before,
  .ant-calendar-header .ant-calendar-next-month-btn::after,
  .ant-calendar-header .ant-calendar-next-century-btn::before,
  .ant-calendar-header .ant-calendar-next-decade-btn::before,
  .ant-calendar-header .ant-calendar-next-year-btn::before,
  .ant-calendar-header .ant-calendar-next-century-btn::after,
  .ant-calendar-header .ant-calendar-next-decade-btn::after,
  .ant-calendar-header .ant-calendar-next-year-btn::after {
    border-color: black !important;
    border-width: 2px 0 0 2px;
  }
  .ant-calendar-header .ant-calendar-prev-month-btn { left: 36px; }
  .ant-calendar-header .ant-calendar-next-month-btn { right: 36px; }
</style>
